var _this7 = this;
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import SearchSelect from '../../components/common/Search';
import { Search } from '@element-plus/icons-vue';
import { uploadFile } from '../../api/qiniuApI';
import Ls from '../../utils/token';
export default {
  name: "User",
  components: {
    SearchSelect: SearchSelect
  },
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '用户角色',
        value: '',
        type: 'select',
        list: []
      }, {
        placeholder: '在线状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '在线'
        }, {
          id: '0',
          name: '离线'
        }]
      }, {
        placeholder: '用户状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '用户手机号',
        value: '',
        type: 'text',
        list: [{
          id: '',
          name: ''
        }]
      }],
      postListData: {
        roleIds: '',
        keyword: '',
        online: '',
        state: '',
        mobile: '',
        pageSize: '',
        pageIndex: ''
      },
      //用户存储的信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //getUserList接口返回表格数据
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      //抽屉数据
      drawerState: '',
      drawerData: {
        username: '',
        mobile: '',
        roleIds: '',
        // 身份
        roleType: '',
        //1前台身份还是2后台身份
        email: '',
        gender: '',
        avatar: '',
        state: ''
      },
      drawer: false,
      direction: 'rtl',
      //性别下拉筛选
      genderOptions: [{
        id: 0,
        name: '保密'
      }, {
        id: 1,
        name: '男'
      }, {
        id: 2,
        name: '女'
      }],
      //角色下拉筛选
      roleOptions: [],
      //搜索关键词
      inputPhone: '',
      // 弹框，显示隐藏控制状态值
      centerDialogVisible: false,
      // 修改，禁用状态时候的id
      userRoleId: '',
      // 重置密码
      pswUser: '',
      // 编辑信息的时候记录原始手机号字段
      oldPhoneNum: '',
      phoneUser: ''
    };
  },
  watch: {},
  mounted: function mounted() {
    this.initRole();
    this.initUserList();
  },
  methods: {
    addFun: function addFun() {
      this.drawerState = 'add';
      this.drawer = true;
      this.drawerData = {
        username: '',
        mobile: '',
        roleIds: '',
        roleType: '',
        email: '',
        gender: '',
        avatar: '',
        state: ''
      };
    },
    // 初始化角色数据
    initRole: function initRole() {
      var _this2 = this;
      //getRoleAll
      this.service.axiosUtils({
        requestName: 'getRoleAll',
        data: {
          type: '' //1是前台，2是后台
        },

        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.roleOptions = res.data;
            _this2.filter[0].list = res.data;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // 初始化用户列表
    initUserList: function initUserList() {
      var _this3 = this;
      // let key=''
      // if(this.inputPhone){
      //    key= this.inputPhone
      // }else{
      //   key=''
      // }

      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;

      //getUserList
      this.service.axiosUtils({
        requestName: 'getUserList',
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.tableData = res.data.list;
            _this3.total = res.data.total;
            _this3.tableData.map(function (item) {
              item.mobileHide = _this3.$common.mGeTel(item.mobile);
            });
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // 修改正常/禁用状态
    editState: function editState(row) {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'editState',
        data: {
          userRoleId: row.userRoleId,
          state: row.state
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            // this.$toast.success(res.message)
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    // 抽屉确定函数
    editAddFun: function editAddFun() {
      var _this5 = this;
      var url = '';
      if (this.drawerState === 'edit') {
        url = 'editUser';
      } else {
        url = 'addUser';
      }
      this.service.axiosUtils({
        requestName: url,
        data: this.drawerData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            // 20240105 如果编辑情况，修改当前登陆用户手机号为新手机号后，当前登陆用户应该退出登陆
            if (url === 'editUser' && _this5.phoneUser && _this5.oldPhoneNum !== _this5.drawerData.mobile) {
              _this5.$toast.success(res.message + '您修改了当前登录用户的手机号，需要重新登录');
              _this5.Ls.removeToken('nof_token');
              _this5.Ls.removeToken('nof_userInfo');
              _this5.$router.push({
                path: '/login'
              });
            } else {
              _this5.$toast.success(res.message);
            }
            _this5.drawer = false;
            _this5.$nextTick(function () {
              this.$refs['form'].resetFields();
            });
            _this5.initUserList();
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    confirmClick: function confirmClick() {
      var _this6 = this;
      var that = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (that.drawerData.email) {
            if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(_this6.drawerData.email)) {
              _this6.$toast.error('请检查邮箱是否符合要求');
              return;
            }
          }
          if (that.file) {
            uploadFile({
              folderName: "logo",
              complete: function complete(res, url) {
                that.drawerData.avatar = url;
                that.editAddFun();
              }
            }, _this6.file);
          } else {
            that.editAddFun();
          }
        }
      });
    },
    // 抽屉取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs['form'].resetFields();
      });
      this.drawer = false;
    },
    //修改图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        // console.log(e)
        _this.drawerData.avatar = this.result;
      };
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initUserList();
    },
    //上传图片转base64
    handleAvatarSuccess: function handleAvatarSuccess(response, uploadFile) {
      _this7.imageUrl = URL.createObjectURL(uploadFile.raw);
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initUserList();
    },
    // 操作函数
    detail: function detail(row, type) {
      var _this8 = this;
      if (type === 'edit') {
        //编辑
        this.drawerState = 'edit';
        this.drawer = true;
        if (!row.gender) {
          this.drawerData.gender = '';
        } else {
          this.drawerData.gender = row.gender;
        }
        this.drawerData.username = row.username;
        this.drawerData.mobile = row.mobile;
        this.drawerData.roleIds = row.roleId;
        this.drawerData.roleType = row.roleType;
        this.drawerData.email = row.email;
        this.drawerData.avatar = row.avatar;
        this.drawerData.state = row.state;
        this.drawerData.userIds = row.userId;
        this.drawerData.userRoleId = row.userRoleId;
        this.drawerData.orgId = row.orgId;

        // 20240105 添加
        this.oldPhoneNum = row.mobile;
        if (this.userInfo.userId === row.userId) {
          this.phoneUser = true;
        } else {
          this.phoneUser = false;
        }
      } else if (type === 'role') {
        //分配新的角色
      } else if (type === 'del') {
        this.centerDialogVisible = true;
        this.userRoleId = row.userRoleId;
      } else {
        //重置密码
        if (this.userInfo.userId === row.userId) {
          this.pswUser = true;
        } else {
          this.pswUser = false;
        }
        this.service.axiosUtils({
          requestName: 'editPassword',
          data: {
            userIds: row.userId
          },
          sucFn: function sucFn(res) {
            if (res.status === 1) {
              if (_this8.pswUser) {
                _this8.$toast.success('您重置了自己的密码，请重新登录');
                _this8.service.axiosUtils({
                  requestName: 'Logout',
                  data: {},
                  sucFn: function sucFn(res) {
                    if (res.status === 1) {
                      _this8.Ls.removeToken('nof_token');
                      _this8.Ls.removeToken('nof_userInfo');
                      _this8.$router.push({
                        path: '/login'
                      });
                    } else {
                      _this8.$toast.error(res.message);
                    }
                  }
                });
              } else {
                _this8.$toast.success(res.message);
              }
            } else {
              _this8.$toast.error(res.message);
            }
          }
        });
      }
    },
    //删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this9 = this;
      //删除当前的角色
      this.service.axiosUtils({
        requestName: 'delFlag',
        data: {
          userRoleId: this.userRoleId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this9.$toast.success(res.message);
            _this9.userRoleId = '';
            _this9.centerDialogVisible = false;
            _this9.currentPage = 1;
            _this9.initUserList();
          } else {
            _this9.$toast.error(res.message);
          }
        }
      });
    },
    searchFun: function searchFun() {
      this.initUserList(this.input);
    },
    searchClear: function searchClear() {
      this.input = '';
      this.initUserList();
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        that.postListData.roleIds = filterData[0].value;
        that.postListData.online = filterData[1].value;
        that.postListData.state = filterData[2].value;
        that.postListData.mobile = filterData[3].value;
      } else {
        that.postListData.roleIds = '';
        that.postListData.online = '';
        that.postListData.state = '';
        that.postListData.mobile = '';
      }
      that.initUserList();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.roleIds = '';
      this.postListData.online = '';
      this.postListData.state = '';
      this.postListData.mobile = '';
      this.currentPage = 1;
      this.initUserList();
    }
  }
};